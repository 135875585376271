.Navbar-adjustment-padding{
    width: 100%;
    height:80px;
}

.about-me-title-holder{
    display: flex;
    justify-content: center;
    align-items: center;
}


.about-me-title{
    width: fit-content;
    font-size: 16px;
    font-weight: 100;
    /* color: #01161E; */
    text-align: center;
    /* margin-top: 50px;
    margin-bottom: 50px; */
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
}

.about-me-title-nepali{
    font-family: Nepali;
    font-size: 30px;
    font-weight: 100;
    /* color: #01161E; */
    margin: 0px;
    margin-top: 30px;
    /* margin-top: 0px;
    margin-bottom: 50px; */
}
.about-me-title-english{
    text-align: center;
    margin-top: 0px;
}

.about-me-card-holder{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 30px 0px;
    /* margin-bottom: 50px; */
}

.about-me-text-card{
    width: 50%;
    min-width: min(300px, 100%);
    max-width: 460px;
    /* margin: 30px,50px,30px,50px; */
    text-align: justify;
}

.about-me-text-description{
    line-height: 1.6;
}

.about-me-image{
    width:300px;
    margin-left: 30px;
}

.about-me-clickables{
    display: flex;
    flex-direction: row;
    width: min(300px,100%);
    justify-content: space-between;
    flex-wrap: wrap;
}
@media screen and (max-width: 300px){
    .about-me-clickables{
        flex-direction: column;
        height: 100px;
        width: 80%;
        padding: 0% 10%;
    }
}
    

.about-me-button{
    /* background-color: #abd996; */
    background-image: linear-gradient(30deg,#90e3f4,#bbd4af);
    border:none;
    border-radius: 20px;
    display: flex;
    justify-content: center;
}
.about-me-button-div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color:#1F271B;
    width: 130px;

    /* margin:0px 10px; */
}

.about-me-button-icon{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}


@media screen and (max-width: 850px){
    .about-me-image{
        width: 200px;
    }
}

@media screen and (max-width: 600px){
    .about-me-image{
        display: none;
    }
    .about-me-text-card{
        width: 80%;
        
    }
}