@font-face {
  font-family: "Nepali";
  src: local("SarashotiRegular"),
    url("./fonts/SarashotiRegular.ttf") format("truetype");
}

.main-body {
  /* background-color: #f3f9e3;
  color: #01161E; */
  
  
  /* background-color: #EFF7F6;
  color: #1F271B; */
  /* background-color: #1b3130;
  color: #EFF7F6; */
  transition: background-color 0.5s ease-in-out, color 0.5s;

  /* transition: color 0.5s ease-in-out; */
   
}
