.contact-me-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    color:#EFF7F6;
    padding-bottom: 20px;
}

.contact-me-contents{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-image: linear-gradient(30deg,#6b488a,#1b6aa1);
    width:min(800px,80vw);
    border-radius: 20px;
}

.contact-info-container{
    display: flex;
    width: min(30vw,300px);
    flex-direction: column;
    justify-content: center;
}

.contact-info-individual-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100px;
    width:150px;
}

.contact-type-header{
    font-size: 16px;
    font-weight: 500;
    text-align: start;
    width:150px;
}
.contact-description{
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    width:150px;
    margin: 0px;
}





.mail-me-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    /* width:450px; */
}

.mail-me-form{
    width: min(40vw,400px);
    font-weight: 1600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}


.mail-me-input-field{
    padding-left: 10px;
    /* width: 400px; */
    border: 1px solid #19271c6d;
    border-radius: 10px;
    height:30px;
    font-size: 16px;
    font-weight: 300;
}

.mail-me-input-field:focus{
    box-shadow: 0 0 5pt 1pt #cfdbff;
    outline: none;
}
.mail-me-input-field:hover{
    box-shadow: 0 0 5pt 1pt #ffcfcf;
    outline: none;
}


.mail-me-message-field{
    padding-left: 10px;
    /* width: 400px; */
    border: 1px solid #19271c6d;
    border-radius: 10px;
    /* height:30px; */
    resize: none;
    font-size: 16px;
    font-weight: 300;
}

.mail-me-message-field:focus{
    box-shadow: 0 0 5pt 1pt #cfdbff;
    outline: none;
}
.mail-me-message-field:hover{
    box-shadow: 0 0 5pt 1pt #ffcfcf;
    outline: none;
}


.other-contacts-container{
    display: flex;
    flex-direction: row ;
    justify-content: flex-start;
    gap: 20px;
    margin-top: -10px;
}


.other-contact-icon{
    width: 30px;
    height: 30px;
    border-radius: 15px;
}
.contact-me-submit-btn{
    width: 100%;
    height:30px;
    border-radius: 7.5px;
    border:none;
    background-color: #0084ff;
    color: white;
    font-size: 16px;
    font-weight: 500;
}



@media screen and (max-width: 600px) {
    .contact-info-container{
        width: 60vw;
        margin-left: 10vw;
        margin-top: 20px;
    }
    .mail-me-form{
        width: 70vw;
    }   
    
    .contact-me-contents{
        flex-direction: column;
    } 
}
